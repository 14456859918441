<div mat-dialog-container fxLayout="column" fxLayoutAlign="center center">
	<h1><strong>¿Seguro que quieres enviar las respuestas?</strong></h1>
	<p class="m-0"><strong>Una vez enviadas no se pondrán modificar.</strong></p>
	<p class="m-0">Si aún no estás seguro de haber finalizado, recuerda que puedes guardar como borrador y continuar en otro momento</p>
</div>

<br />
<hr />
<br />

<div class="buttons" fxLayout="row" fxLayoutAlign="end center">
	<button mat-raised-button class="m-8 btn-outline btn-feedbalia" (click)="confirm()">
		<mat-icon>send</mat-icon>
		<strong>Enviar evaluación</strong>
	</button>
	<button mat-raised-button class="m-8 btn-outline btn-borrador" (click)="close()">
		<strong>Cancelar</strong>
	</button>
</div>
